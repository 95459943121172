<!-- index -->
<template>
  <div class="companyMap">
    <baidu-map
      class="bm-view"
      :center="{ lng: 113.961026, lat: 22.536649 }"
      :zoom="18"
      :scroll-wheel-zoom="true"
    >
      <bm-marker
        :position="{ lng: 113.961026, lat: 22.536649 }"
        :dragging="true"
        animation="BMAP_ANIMATION_BOUNCE"
      />
    </baidu-map>
    <!-- <baidu-map class="bm-view">
  </baidu-map> -->
  </div>
</template>

<script>
// 导入的其他文件 例如：import moduleName from 'modulePath';
export default {
  name: "CompanyMap",
  //import所引入的组件注册
  components: {},

  data() {
    return {};
  },

  //监听属性
  computed: {},

  //监控data中的数据变化
  watch: {},

  //方法集合
  methods: {},

  //创建完成 访问当前this实例
  created() {},
  //挂载完成 访问DOM元素
  mounted() {},
};
</script>
<style lang="scss" scoped>
.companyMap {
  width: 100%;
  height: 100%;
}
.bm-view {
  width: 100%;
  height: 100%;
}
</style>