<!-- index -->
<template>
  <div class="about">
    <div class="main-theme">
      <div class="main-title">
        <span class="title-us">ABOUT US</span>
        <span class="title-cn">关于我们</span>
      </div>
    </div>
    <div class="main-content">
      <p class="content-title">公司简介</p>
      <p class="content-main">
        枫华嘉美是从事跨境电商物流的优质服务商，依托华运国际物流二十年经验和平台优势，打造出精品北美FBA头程物流线路。
        <br />
        我司在美国洛杉矶和纽约设有分公司，自营20万尺海外仓，拥有搜航IT团队的支持，全球拥有丰富的海外代理资源，能够为您提供安全、专业的物流运输服务。
        <br />
        优势渠道：美国整柜、散货海运卡派，以星快船，美森快船，盐田海派，美国空派等
      </p>
      <br />

      <p class="content-title">联系我们</p>
      <p class="content-mains">电话：(86) 0755-22960000</p>
      <p class="content-mains">地址：广东省深圳市南山区沙河西路1841号深圳湾科技生态园二区7栋B座东区 911-912室</p>
      <div class="map-class">
        <company-map></company-map>
      </div>
      <!-- <div class="main-contact">
        <div class="contact-item">
          <p class="item-name">kobe</p>
          <span>邮箱：sales01@fbahope.com</span>
          <span>手机：13827308998</span>
        </div>
        <div class="contact-item">
          <p class="item-name">Stan</p>
          <span>邮箱：sales02@fbahope.com </span>
          <span>手机：18287296475</span>
        </div>
      </div> -->
    </div>
    <div class="contactUs" @click="tous">
      <img src="../../assets/img/pc/icon_online_counsel_pc.png" alt="" />
      <span> 在线咨询 </span>
    </div>
  </div>
</template>

<script>
// 导入的其他文件 例如：import moduleName from 'modulePath';
import CompanyMap from "./CompanyMap.vue";
export default {
  name: "About",
  //import所引入的组件注册
  components: {
    CompanyMap,
  },

  data() {
    return {};
  },

  //监听属性
  computed: {},

  //监控data中的数据变化
  watch: {},

  //方法集合
  methods: {
    toown(){
      window.open('https://wpa1.qq.com/lEzuwBTa?_type=wpa&qidian=true')
    },
    //联系我们
    tous(){
      window.open('https://wpa1.qq.com/byOBtjMx?_type=wpa&qidian=true')
    },
  },

  //创建完成 访问当前this实例
  created() {
    let info = this.$store.state.user.info;
    console.log(info,'about')
  },
  //挂载完成 访问DOM元素
  mounted() {},
};
</script>
<style scoped lang="scss">
.about {
  width: 100%;
  min-height: calc(100vh - 92px);
  background: #eef2f6;
}
.map-class {
  width: 100%;
  height: 240px;
  margin-top: 20px;
}
.content-mains {
  font-size: 14px;
  font-weight: 400;
  color: #343537;
  line-height: 1.5;
  margin-bottom: 2px;
}
.contactUs {
  position: fixed;
  right: 0;
  bottom: 220px;
  width: 40px;
  height: 132px;
  background: #135697;
  padding: 12px 0;
  box-sizing: border-box;
  cursor: pointer;
  img {
    display: block;
    margin: 0 auto;
    padding: 0 0 5px 0;
    box-sizing: border-box;
  }
  span {
    display: block;
    width: 16px;
    margin: 0 auto;
    line-height: 20px;
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 400;
  }
}
</style>
<style lang="scss" scoped>
@import "../../assets/css/base.scss";
</style>